<template>
  <v-container class="fill-height">
    <v-responsive class="d-flex align-center fill-height">
      <div class="d-flex justify-center">
        <v-card width="760px" shaped color="#eef">
          <v-row dense>
            <v-col cols="12" sm="6" class="flex-grow-1 flex-shrink-0">
              <v-img src="@/assets/230.png" contain class="ma-3"></v-img>
            </v-col>

            <v-col cols="12" sm="6" class="flex-grow-1 flex-shrink-0">
              <div class="mx-4">
                <v-card-title class="text-h5"
                  ><strong>APERDU</strong>
                </v-card-title>

                <v-card-subtitle
                  >Aplikasi Perencanaan Anggaran Terpadu
                </v-card-subtitle>

                <v-form @submit.prevent="onRequestCaptcha">
                  <v-card-text>
                    <v-text-field
                      v-model="username"
                      label="username"
                      prepend-inner-icon="mdi-account"
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      label="password"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                      hide-details
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      rounded
                      type="submit"
                      class="ma-2 primary"
                      dark
                      large
                    >
                      LOGIN
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div class="d-flex justify-center">
        <v-card-subtitle align="center">
          Copyright © 2023 -
          <strong
            >Kantor Wilayah Kementerian Hukum dan HAM Kalimantan Barat</strong
          >
        </v-card-subtitle>
      </div>
    </v-responsive>

    <v-snackbar v-model="snackbar" color="error" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogCaptcha" width="320">
      <v-card>
        <v-card-title class="text-h5 primary white--text d-flex justify-center">
          Jawab pertanyaan ini!
        </v-card-title>

        <br />

        <v-card-text class="d-flex justify-center">
          <div v-html="gambar"></div>
        </v-card-text>

        <v-card-text class="d-flex justify-center">
          <v-btn color="primary" @click="onRefreshCaptcha()" small>
            <v-icon left class="mr-1">mdi-refresh</v-icon> Refresh
          </v-btn>
        </v-card-text>
        <v-form @submit.prevent="onLogin">
          <v-card-text>
            <v-text-field
              v-model="jawaban"
              label="Jawaban"
              outlined
              hide-details
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="d-flex justify-center">
            <v-btn type="submit" color="success" class="my-2">
              <v-icon left class="mr-1">mdi-check-bold</v-icon> Verifikasi
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      username: "",
      password: "",
      snackbar: false,
      snackbarText: "",
      dialogCaptcha: false,
      hash: "",
      jawaban: "",
      gambar: "",
    };
  },

  methods: {
    onRequestCaptcha() {
      if (this.username != "" && this.password != "") {
        this.$store.dispatch("getInfoCaptcha").then((res) => {
          this.hash = res.data.hash;
          this.gambar = res.data.gambar;
          this.dialogCaptcha = true;
        });
      } else {
        this.snackbarText = "Username dan Password tidak boleh kosong.";
        this.snackbar = true;
      }
    },
    onRefreshCaptcha() {
      this.$store.dispatch("getInfoCaptcha").then((res) => {
        this.hash = res.data.hash;
        this.gambar = res.data.gambar;
      });
    },
    onLogin() {
      const formData = {
        username: this.username,
        password: this.password,
        hash: this.hash,
        jawaban: this.jawaban,
      };
      if (this.jawaban != "") {
        this.$store.dispatch("login", formData).then((value) => {
          if (value.hasil == "error") {
            this.snackbarText = value.pesan;
            this.snackbar = true;
          }
        });
      } else {
        this.snackbarText = "Jawaban tidak boleh kosong.";
        this.snackbar = true;
      }
    },
  },
};
</script>